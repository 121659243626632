<template>
  <v-app>
    <v-main>
      <Navbar />
      <Cookiebar />
      <router-view/>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Cookiebar from "./components/Cookiebar";

export default {

  name: 'App',
  metaInfo: {
      title: 'MeinHaus-3D',
      titleTemplate: '%s | MeinHaus-3D'
    },

  data: () => ({
  }),

  components: {
    Navbar,
    Footer,
    Cookiebar
  },
  
};
</script>
