// https://www.mein-datenschutzbeauftragter.de/blog/wie-nutze-ich-google-analytics-datenschutzkonform-und-somit-rechtssicher/
<template>
        <v-main class="teal lighten-5 py-6 fill-height">
            <v-container>
                <v-row class="mb-6">
                    <v-col offset-xl="1">
                        <v-btn large text @click="$router.push('/')"><v-icon class="mr-2">mdi-arrow-left-circle</v-icon>zurück</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xl="8" offset-xl="2">
                        <v-tabs v-model="tab" color="teal" background-color="rgba(0,0,0,0)">
                            <v-tab>Impressum</v-tab>
                            <v-tab>AGB</v-tab>
                            <v-tab>Datenschutzerklärung</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab" style="background-color: rgba(0,0,0,0)">
                            <v-tab-item color="none">
                                <h5 class="my-8">Impressum</h5>
                                <span> Verantwortlich für den Inhalt dieser Seite:<br /> </span>
                                <div class="ml-6 mt-4">
                                    <p><b>Andreas Heberlein</b></p>
                                    Auf der Erzgrub 1<br />
                                    65624 Altendiez<br />
                                    0175 924 13 14<br />
                                    info[at]meinhaus-3d.de<br />
                                </div>
                            </v-tab-item>
                            <v-tab-item style="background-color: rgba(0,0,0,0)">
                                <h5 class="my-8">Allgemeine Geschäftsbedingungen (AGB)</h5>
                                <p><strong>Geltungsbereich</strong></p>
                                    <p>
                                        Für alle Lieferungen von MeinHaus-3D an Verbraucher
                                        gelten diese Allgemeinen Geschäftsbedingungen (AGB).<br />
                                        Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft
                                        zu einem Zwecke abschließt, der überwiegend weder ihrer
                                        gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit
                                        zugerechnet werden kann.
                                    </p>

                                    <p><strong>Vertragspartner</strong></p>
                                    <p>
                                        Der Kaufvertrag kommt zustande mit Andreas Heberlein, Auf der Erzgrub 1, 65624 Altendiez.
                                    </p>

                                    <p><strong>Vertragsschluss</strong></p>
                                    <p>
                                        Die Darstellung der Produkte auf der Webseite stellt kein
                                        rechtlich bindendes Angebot, sondern nur eine Aufforderung zur
                                        Anfrage eines persönlichen Angebots dar.<br />
                                        Durch schriftliche Bestätigung per E-Mail geben Sie eine 
                                        verbindliche Bestellung der für Sie individuell angebotenen 
                                        Waren ab. Ihr Kaufvertrag kommt zustande, wenn wir
                                        Ihre Bestellung durch eine Auftragsbestätigung per E-Mail nach
                                        dem Erhalt Ihrer Bestellung annehmen.<br />
                                    </p>

                                    <p><strong>Widerrufsrecht</strong></p>
                                    <p>
                                        Wenn Sie Verbraucher sind (also eine natürliche Person, die die
                                        Bestellung zu einem Zweck abgibt, der weder Ihrer gewerblichen
                                        oder selbständigen beruflichen Tätigkeit zugerechnet werden
                                        kann), steht Ihnen nach Maßgabe der gesetzlichen Bestimmungen
                                        ein Widerrufsrecht zu.<br />
                                        Machen Sie als Verbraucher von Ihrem Widerrufsrecht nach Ziffer
                                        4.1 Gebrauch, so haben Sie die regelmäßigen Kosten der
                                        Rücksendung zu tragen.<br />
                                        Im Übrigen gelten für das Widerrufsrecht die Regelungen, die im
                                        Einzelnen wiedergegeben sind in der folgenden
                                    </p>
                                    <h5 class="h5">Widerrufsbelehrung</h5>
                                    <div class="ml-6 mt-2">
                                        <h5 class="h6">Widerrufsrecht</h5>
                                        <p>
                                        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
                                        Gründen diesen Vertrag zu widerrufen.<br />
                                        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie
                                        oder ein von Ihnen benannter Dritter, der nicht der Beförderer
                                        ist, die Waren in Besitz genommen haben bzw. hat.<br />
                                        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns mittels einer
                                        eindeutigen Erklärung (z.B. per E-Mail) über Ihren Entschluss,
                                        diesen Vertrag zu widerrufen, informieren. Sie können dafür
                                        das beigefügte Muster-Widerrufsformular verwenden, das jedoch
                                        nicht vorgeschrieben ist. Sie können das Muster-
                                        Widerrufsformular oder eine andere eindeutige Erklärung
                                        ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit
                                        Gebrauch, so werden wir Ihnen zeitnah per E-Mail eine
                                        Bestätigung über den Eingang eines solchen Widerrufs
                                        übermitteln.<br />
                                        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                                        Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
                                        der Widerrufsfrist absenden.
                                        </p>
                                        <h5 class="h5">Muster-Widerrufsformular</h5>
                                        <p>
                                        Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
                                        dieses Formular aus und senden Sie es zurück.
                                        </p>
                                        <div class="ml-4" style="font-size:0.8em">
                                        <p>
                                            An:<br />
                                            Andreas Heberlein<br />
                                            Limpericher Str. 50<br />
                                            53225 Bonn<br />
                                            E-Mail-Adresse: info@meinhaus-3D.de
                                        </p>

                                        <p>
                                            Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
                                            abgeschlossenen Vertrag über den Kauf der folgenden Waren
                                            (*)/die Erbringung der folgenden Dienstleistung (*):
                                        </p>
                                        <p>
                                            Bestellt am (*)/erhalten am (*):
                                        </p>
                                        <p>
                                            Name des/der Verbraucher(s):<br />
                                            Anschrift des/der Verbraucher(s):<br />
                                            Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf
                                            Papier):<br />
                                            Datum:<br />
                                        </p>
                                        <p>
                                            * nicht Zutreffendes bitte streichen
                                        </p>
                                        </div>
                                        <h5 class="h5">Folgen des Widerrufs</h5>
                                        <p>
                                        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
                                        Zahlungen, die wir von Ihnen erhalten haben, einschließlich
                                        der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
                                        sich daraus ergeben, dass Sie eine andere Art der Lieferung
                                        als die von uns angebotene, günstigste Standardlieferung
                                        gewählt haben), unverzüglich und spätestens binnen vierzehn
                                        Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
                                        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für
                                        diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
                                        Sie bei der ursprünglichen Transaktion eingesetzt haben, es
                                        sei denn, mit Ihnen wurde ausdrücklich etwas anderes
                                        vereinbart; in keinem Fall werden Ihnen wegen dieser
                                        Rückzahlung Entgelte berechnet. Wir können die Rückzahlung
                                        verweigern, bis wir die Waren wieder zurückerhalten haben oder
                                        bis Sie den Nachweis erbracht haben, dass Sie die Waren
                                        zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt
                                        ist.
                                        </p>
                                        <p>
                                        Sie haben die Waren unverzüglich und in jedem Fall spätestens
                                        binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den
                                        Widerruf dieses Vertrages unterrichten, an uns zurückzusenden
                                        oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren
                                        vor Ablauf der Frist von vierzehn Tagen absenden.
                                        </p>
                                        <p>
                                        Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
                                        </p>
                                        <p>
                                        Sie müssen für einen etwaigen Wertverlust der Waren nur
                                        aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der
                                        Beschaffenheit, Eigenschaften und Funktionsweise der Waren
                                        nicht notwendigen Umgang mit ihnen zurückzuführen ist.
                                        </p>
                                    </div>
                                    <h5 class="h5">- Ende der Widerrufsbelehrung -</h5>

                                    <p><strong>Preise und Versandkosten</strong></p>
                                    <p>
                                        Aufgrund der Kleinunternehmer-Regelung im Sinne von § 19 Abs. 1 UStG
                                        wird auf den Produktseiten und der Rechnung keine Umsatzsteuer berechnet.<br />
                                        Zusätzlich zu den angegebenen Preisen berechnen wir für die
                                        Lieferung innerhalb Deutschlands pauschal 7,95 Euro pro 
                                        Bestellung. Die Versandkosten werden Ihnen vor Vertragsabschluss
                                        nochmals deutlich mitgeteilt.
                                    </p>

                                    <p><strong>Lieferung</strong></p>
                                    <p>
                                        Die Lieferung erfolgt nur innerhalb Deutschlands mit DHL.
                                    </p>

                                    <p><strong>Zahlung</strong></p>
                                    <p>
                                        Die Zahlung erfolgt normalerweise per Vorkasse. Wir nennen 
                                        Ihnen unsere Bankverbindung in der Auftragsbestätigung und 
                                        liefern die Ware nach Zahlungseingang.
                                    </p>

                                    <p><strong>Eigentumsvorbehalt</strong></p>
                                    <p>
                                        Bis zur vollständigen Zahlung bleibt die Ware unser Eigentum.
                                    </p>

                                    <p><strong>Streitbeilegung</strong></p>
                                    <p>
                                        Die EU-Kommission hat eine Internetplattform zur
                                        Online-Beilegung von Streitigkeiten geschaffen. Die Plattform
                                        dient als Anlaufstelle zur außergerichtlichen Beilegung von
                                        Streitigkeiten betreffend vertragliche Verpflichtungen, die aus
                                        Online-Kaufverträgen erwachsen. Nähere Informationen sind unter
                                        dem folgenden Link verfügbar:
                                        <a href="http://ec.europa.eu/consumers/odr" target="_blank">
                                        http://ec.europa.eu/consumers/odr</a
                                        >. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
                                        Verbraucherschlichtungsstelle sind wir weder bereit noch
                                        verpflichtet.
                                    </p>
                            </v-tab-item>
                            <v-tab-item style="background-color: rgba(0,0,0,0)">
                                <h5 class="my-8">Datenschutzerklärung</h5>
                                <p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
                                <p><strong>Benennung der verantwortlichen Stelle</strong></p>
                                <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                                <p><span id="s3-t-ansprechpartner">Andreas Heberlein</span><br><span id="s3-t-strasse">Auf der Erzgrub 1</span><br><span id="s3-t-plz">65624</span> <span id="s3-t-ort">Altendiez</span></p><p></p>
                                <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>

                                <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
                                <p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

                                <p><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></p>
                                <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>

                                <p><strong>Recht auf Datenübertragbarkeit</strong></p>
                                <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

                                <p><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></p>
                                <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>

                                <p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
                                <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

                                <p><strong>Server-Log-Dateien</strong></p>
                                <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                                <ul>
                                    <li>Besuchte Seite auf unserer Domain</li>
                                    <li>Datum und Uhrzeit der Serveranfrage</li>
                                    <li>Browsertyp und Browserversion</li>
                                    <li>Verwendetes Betriebssystem</li>
                                    <li>Referrer URL</li>
                                    <li>Hostname des zugreifenden Rechners</li>
                                    <li>IP-Adresse</li>
                                </ul>
                                <p>Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
                                            
                                <p><strong>Datenübermittlung bei Vertragsschluss für Warenkauf und Warenversand</strong></p>
                                <p>Personenbezogene Daten werden an Dritte nur übermittelt, sofern eine Notwendigkeit im Rahmen der Vertragsabwicklung besteht. Dritte können beispielsweise Bezahldienstleister oder Logistikunternehmen sein. Eine weitergehende Übermittlung der Daten findet nicht statt bzw. nur dann, wenn Sie dieser ausdrücklich zugestimmt haben.</p>
                                <p>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
                                            
                                <p><strong>Kontaktformular</strong></p>
                                <p>Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</p>
                                <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
                                <p>Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</p>
                                            
                                <p><strong>Cookies</strong></p>
                                <p>Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. </p>
                                <p>Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf
                                    unserer Website wiederzuerkennen.</p>
                                <p>Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies
                                    kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.</p>
                                <p>Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser
                                    Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser
                                    Datenschutzerklärung separat behandelt.</p>
                                            
                                <p><strong>Google Analytics</strong></p>
                                <p>Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
                                <p>Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen über Ihre Benutzung unserer Website
                                    werden an einen Server von Google übermittelt und dort gespeichert. Server-Standort ist im Regelfall die USA.</p>
                                <p>Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und ggf. auch Werbung zu optimieren.</p>
                                <p>IP-Anonymisierung</p>
                                <p>Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
                                    vor der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen Google die volle IP-Adresse an einen Server in den USA überträgt und dort kürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                                    auszuwerten, um Reports über Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Es findet keine Zusammenführung der von Google Analytics übermittelten
                                    IP-Adresse mit anderen Daten von Google statt.</p>
                                <p>Browser Plugin</p>
                                <p>Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt
                                    anschließender Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das über folgenden Link erreichbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
                                <p>Widerspruch gegen die Datenerfassung</p>
                                <p>Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen unserer Website verhindert: <strong style="background: yellow">Google Analytics deaktivieren</strong>.</p>
                                <p>Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
                                <p>Auftragsverarbeitung</p>
                                <p>Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit Google einen Vertrag über die Auftragsverarbeitung abgeschlossen.</p>
                                <p>Demografische Merkmale bei Google Analytics</p>
                                <p>Unsere Website verwendet die Funktion “demografische Merkmale” von Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung
                                    von Google sowie aus Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu einer bestimmten Person ist nicht möglich. Sie können diese Funktion jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem Google-Konto möglich oder
                                    indem Sie die Erfassung Ihrer Daten durch Google Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung” erläutert, generell untersagen.</p>
                                            
                                <p><strong>Google AdSense</strong></p>
                                <p>Unsere Website verwendet Google AdSense. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
                                <p>Google AdSense dient der Einbindung von Werbeanzeigen und setzt Cookies. Cookies sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert, um die Nutzung der Website analysieren. Google AdSense setzt außerdem Web Beacons ein. Web Beacons
                                    sind unsichtbare Grafiken, die eine Analyse des Besucherverkehrs auf unserer Wesite ermöglichen.</p>
                                <p>Durch Cookies und Web Beacons erzeugten Informationen werden an Server von Google übertragen und dort gespeichert. Serverstandort sind die USA. Google kann diese Informationen an Vertragspartner weiterreichen. Ihre IP-Adresse wird Google jedoch nicht
                                    mit anderen von Ihnen gespeicherten Daten zusammenführen.</p>
                                <p>Die Speicherung von AdSense-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir als Websitebetreiber haben ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und unsere Werbung zu optimieren.</p>
                                <p>Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken und unterbinden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben. Durch die Nutzung unserer Website erklären
                                    Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise sowie dem zuvor benannten Zweck einverstanden.</p>
                                            
                                <p><strong>Google AdWords und Google Conversion-Tracking</strong></p>
                                <p>Unsere Website verwendet Google AdWords. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States.</p>
                                <p>AdWords ist ein Online-Werbeprogramm. Im Rahmen des Online-Werbeprogramms arbeiten wir mit Conversion-Tracking. Nach einem Klick auf eine von Google geschaltete Anzeige wird ein Cookie für das Conversion-Tracking gesetzt. Cookies sind kleine Textdateien,
                                    die Ihr Webbrowser auf Ihrem Endgerät speichert. Google AdWords Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer. Am Cookie können Google und wir erkennen, dass Sie auf eine Anzeige geklickt
                                    haben und zu unserer Website weitergeleitet wurden.</p>
                                <p>Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies sind nicht über Websites von AdWords-Kunden nachverfolgbar. Mit Conversion-Cookies werden Conversion-Statistiken für AdWords-Kunden, die Conversion-Tracking einsetzen, erstellt. Adwords-Kunden
                                    erfahren wie viele Nutzer auf ihre Anzeige geklickt haben und auf Seiten mit Conversion-Tracking-Tag weitergeleitet wurden. AdWords-Kunden erhalten jedoch keine Informationen, die eine persönliche Identifikation der Nutzer ermöglichen. Wenn Sie nicht
                                    am Tracking teilnehmen möchten, können Sie einer Nutzung widersprechen. Hier ist das Conversion-Cookie in den Nutzereinstellungen des Browsers zu deaktivieren. So findet auch keine Aufnahme in die Conversion-Tracking Statistiken statt.</p>
                                <p>Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir als Websitebetreiber haben ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und unsere Werbung zu optimieren.</p>
                                <p>Einzelheiten zu Google AdWords und Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von Google: <a href="https://www.google.de/policies/privacy/">https://www.google.de/policies/privacy/</a>.</p>
                                <p>Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.</p>
                                            
                                <p><strong>Google Web Fonts</strong></p>
                                <p>Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
                                <p>Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.</p>
                                <p>Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.</p>
                                <p>Einzelheiten über Google Web Fonts finden Sie unter: <a href="https://www.google.com/fonts#AboutPlace:about">https://www.google.com/fonts#AboutPlace:about</a> und weitere Informationen in den Datenschutzbestimmungen von Google: <a href="https://policies.google.com/privacy/partners?hl=de">https://policies.google.com/privacy/partners?hl=de</a></p>
                                <p><small>Quelle: Datenschutz-Konfigurator von <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">mein-datenschutzbeauftragter.de</a></small></p>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
</template>

<script>
export default {
    metaInfo: {
      title: 'Impressum',
    },
    data() {
    return {
      tab: null
    };
  },
}
</script>

<style>

</style>