<template>
  <section class="teal lighten-4">
    <div class="pt-6 pb-12">
      <v-row justify="center">
        <h4 class="subheading font-weight-thin">
          Beispielprojekte
        </h4>
      </v-row>
      <v-row justify="center">
        <v-col v-for="(ref, id) in references" :key="id" cols="12" md="4">
          <v-card
            class="ma-2"
            @click="navReference(ref)"
          >
            <v-carousel
              cycle
              hide-delimiters
              show-arrows-on-hover
              height="100%"
              :interval= ref.interval
            >
              <v-carousel-item v-for="(refImg, k) in ref.img.slice(0,3)" :key="k">
                <v-img
                  :src= refImg.src
                  :lazy-src= refImg.lazy
                  :aspect-ratio="16/9"
                >
                </v-img>
              </v-carousel-item>
            </v-carousel>
            <v-card-title>{{ ref.title }}</v-card-title>
            <v-card-subtitle>{{ ref.subtitle }}</v-card-subtitle>
            <!-- <v-card-text class="text--primary">
              <div>{{ ref.info }}</div>
              <div><strong>{{ ref.price }}</strong></div>
            </v-card-text> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="teal"
                text
                @click="navReference(ref)"
              >
                weitere Infos
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>
      
<script>
  export default {
    data: () => ({
      references: [
        {
          title: 'Bungalow',
          subtitle: 'Kompaktes Wohnobjekt auf 1 Etage',
          info: 'Erstellung des Modells & 3D-Druck',
          description: 'Dieses Modell besteht aus zwei Teilen; dem Grundgeschoss und dem Satteldach. Die beiden Teile liegen lose aufeinander, durch kleine Erhebungen rastet das Dach allerdings mittig über dem Grundgeschoss ein.\n\nDas Modell wurde auf Basis eines Grundrisses mit präzisen Angaben zur Raumaufteilung und der Bemaßung erstellt.',
          scale: '1:100',
          width: '12,6',
          depth: '4,4',
          height: '4,0',
          price: 'EUR 119,-',
          img:[
            { src: require('@/assets/refs/Modell_B-1.jpg'), lazy: require('@/assets/refs/80/Modell_B-1.jpg'), },
            { src: require('@/assets/refs/Modell_B-2.jpg'), lazy: require('@/assets/refs/80/Modell_B-2.jpg'), },
            { src: require('@/assets/refs/Modell_B-3.jpg'), lazy: require('@/assets/refs/80/Modell_B-3.jpg'), },
            { src: require('@/assets/refs/Modell_B-4.jpg'), lazy: require('@/assets/refs/80/Modell_B-4.jpg'), },
            { src: require('@/assets/refs/Modell_B-5.jpg'), lazy: require('@/assets/refs/80/Modell_B-5.jpg'), },
          ],
          interval: 7000,
        },
        {
          title: 'Einfamilienhaus',
          subtitle: 'Wohnobjekt auf 2 Etagen',
          info: 'Erstellung des Modells & 3D-Druck',
          description: 'Dieses Modell besteht aus ingesamt vier Teilen; Erdgeschoss, Obergeschoss, Dachgeschoss und das Satteldach. Die einzelnen Ebenen haben kleine Nasen, dadurch ist das Objekt einfach zu zerlegen und wieder zusammenzusetzen.\n\nDas Modell wurde auf Basis detaillierter Fotos der Baupläne sowie ergänzenden Angaben zu Raumhöhe und weiteren Maßen erstellt.',
          scale: '1:100',
          width: '11,1',
          depth: '10,7',
          height: '8,4',
          price: 'EUR 279,-',
          img:[
            { src: require('@/assets/refs/Modell_A-1.jpg'), lazy: require('@/assets/refs/80/Modell_A-1.jpg'), },
            { src: require('@/assets/refs/Modell_A-2.jpg'), lazy: require('@/assets/refs/80/Modell_A-2.jpg'), },
            { src: require('@/assets/refs/Modell_A-3.jpg'), lazy: require('@/assets/refs/80/Modell_A-3.jpg'), },
            { src: require('@/assets/refs/Modell_A-4.jpg'), lazy: require('@/assets/refs/80/Modell_A-4.jpg'), },
            { src: require('@/assets/refs/Modell_A-5.jpg'), lazy: require('@/assets/refs/80/Modell_A-5.jpg'), },
          ],
          interval: 7500,
        },
      ],
    }),
    methods: {
      notSupported() {
        alert("Not supported yet 🤷‍♂️")
      },
      navReference(ref){
        this.$gtag.event('ReferenceNav' + ref.title, {'event_category': 'Navigation', 'value': 'Ref-' + ref.title})
        this.$router.push({ name: 'Reference', params: { reference: ref } })
      }
    },
  }
</script>
      
<style>
      
</style>