<template>
    <v-footer padless dark bottom>
        <v-col
        class="text-center teal darken-4 py-4"
        cols="12"
        >
        © {{ new Date().getFullYear() }} <strong>MeinHaus-3D</strong><br/>
        <v-btn @click="navImpressum" text class="caption">Impressum, AGB & Datenschutzerklärung</v-btn>
        </v-col>
    </v-footer>
</template>
      
      <script>
      export default {
          methods:{
            navImpressum(){
                this.$router.push('Impressum');
            }

          }
      }
      </script>
      
      <style>
      
      </style>