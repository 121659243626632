//https://www.genuitec.com/upload-files-using-vue-and-firebase/

<template>
    <section if="requestForm">
        <v-layout
          column
          wrap
          align-center
          class="py-6 mb-8"
          text-center
        >
            <h4 class="subheading font-weight-thin mb-6">
                Unverbindliche Anfrage
            </h4>
            <v-col cols="12" md="6" v-if="status=='IDLE'|| status=='LOADING'">
              <p>
                Senden Sie uns gerne eine unverbindliche Anfrage.<br/>Wir melden uns zeitnah mit einem persönlichen Angebot bei Ihnen.
              </p>
                <v-form
                  ref="form"
                  v-model="valid"
                  method="POST"
                  lazy-validation
                  @submit="submitRequest"
                  :disabled="submitting"
                >
                    <v-text-field
                    v-model="name"
                    label="Name"
                    outlined
                    required
                    maxlength="40"
                    :rules="nameRules"
                    ></v-text-field>
                    <v-text-field
                    v-model="email"
                    label="E-Mail"
                    outlined
                    required
                    maxlength="40"
                    :rules="emailRules"
                    ></v-text-field>
                    <v-text-field
                    v-model="phone"
                    label="Telefon/Mobil (optional)"
                    type="tel"
                    outlined
                    maxlength="15"
                    ></v-text-field>
                    <v-textarea
                    v-model="message"
                    label="Beschreiben Sie Ihre Anfrage"
                    outlined
                    required
                    auto-grow
                    rows="5"
                    :rules="messageRules"
                    ></v-textarea>

                    <v-file-input
                      color="teal"
                      accept="image/*, application/pdf"
                      v-model="attachments"
                      chips
                      counter
                      multiple
                      show-size
                      truncate-length="30"
                      outlined
                      placeholder="Fotos/Dokumente anhängen"
                    ></v-file-input>

                    <v-checkbox
                    v-model="checkbox"
                    label="Ich akzeptiere die Hinweise zur Datenverarbeitung in der Datenschutzerklärung"
                    outlined
                    required
                    :rules="checkboxRules"
                    ></v-checkbox>
                    <v-layout
                    column
                    wrap
                    align-center
                    v-if="status!='LOADING'"
                    >
                        <v-btn
                        class="teal mb-4"
                        dark
                        @click.prevent="submitRequest"
                        >
                        Anfrage senden
                        </v-btn>
                        <v-btn @click="reset" text>
                        Felder leeren
                        </v-btn>
                    </v-layout>
                    <v-layout
                    column
                    wrap
                    align-center
                    v-if="status=='LOADING'"
                    > 
                      <v-progress-circular
                        indeterminate
                        color="teal"
                        class="my-8"
                      ></v-progress-circular>
                      <v-progress-linear color="teal" :value="uploadValue"></v-progress-linear>
                    </v-layout>
                </v-form>
            </v-col>
            <v-col cols="12" md="6" v-if="status=='SUCCESS'">
              <v-card outlined class="pa-4 font-weight-light" color="teal lighten-5">
                <p><v-icon x-large color="teal">mdi-check-circle-outline</v-icon><br/>
                Vielen Dank, Ihre Anfrage wurde übermittelt.</p>
                <p>Wir melden uns zeitnah mit einem persönlichen Angebot bei Ihnen.<br/>
                Bitte kontaktieren Sie uns bei Rückfragen unter <a href="mailto:info@meinhaus-3d.de">info@meinhaus-3d.de</a></p>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" v-if="status=='ERROR'">
              <v-card outlined class="pa-4 font-weight-light" style="border: 2px solid red">
                <p><v-icon x-large color="red">mdi-alert-circle-outline</v-icon><br/>
                Leider konnte die Anfrage nicht übermittelt werden.</p>
                <p>Bitte versuchen Sie es erneut oder schreiben Sie uns direkt eine E-Mail an <a href="mailto:info@meinhaus-3d.de">info@meinhaus-3d.de</a>.</p>
                <v-btn text @click="status='IDLE'">erneut versuchen</v-btn>
              </v-card>
            </v-col>
        </v-layout>
    </section>
</template>

<script>
  import firebase from 'firebase/compat'


  export default {
    data: () => ({
      myFile: null,
      name: '',
      email: '',
      phone: '',
      message: '',
      checkbox: false,
      attachments: null,
      valid: true,
      requestId: '',
      uploadValue: 0,
      // IDLE - LOADING - SUCCESS - ERROR
      status: "IDLE",
      nameRules: [
        v => !!v || 'Bitte Namen angeben',
        v => (v && v.length <= 40) || 'Die maximale Länge beträgt 40 Zeichen',
      ],
      emailRules: [
        v => !!v || 'Bitte E-Mail angeben',
        v => /.+@.+\..+/.test(v) || 'E-mail Adresse ungültig',
      ],
      messageRules: [
        v => !!v || 'Bitte beschreiben Sie Ihre Anfrage',
      ],
      checkboxRules: [
        v => !!v || 'Bitte bestätigen Sie den Hinweis',
      ],
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      submitRequest: function () {
        if(this.$refs.form.validate()){
          // e.preventDefault()
          this.status = "LOADING"
          var attachmentCount = 0
          if(this.attachments)
            attachmentCount = this.attachments.length
          const messagesRef = this.$firebaseDatabase.collection('Anfragen')
          messagesRef.add(
            {
              name: this.name,
              email: this.email,
              phone: this.phone,
              message: this.message,
              attachments: attachmentCount,
              checkbox: this.checkbox,
              time: new Date(),
            },
          )
          .then(ref=>{
            this.requestId = ref.id
            this.uploadFiles()
          })
          .catch(ref => {
            console.log(ref)
            this.status="ERROR"
          });
        }
      },
      async uploadFiles() {
        for(var n = 0; n < this.attachments.length; n++){
            var file = this.attachments[n]
            // console.log("Uploading file "+(n+1)+" of " + this.attachments.length)
            this.uploadValue = ((n+1)/this.attachments.length)*100
            try {
                if (file && file.name) {
                  this.status="LOADING"

                    const fr = new FileReader();
                    fr.readAsDataURL(file);

                    const imgData = new FormData();
                    imgData.append("image", this.myFile);
                    const filePath = `${this.requestId}`+ ' - ' +`${this.name}`+'/'+`${file.name}`

                    await firebase.storage().ref()
                    .child(filePath)
                    .put(this.myFile);
                }
            } catch (e) {
                console.error(e);
            } 
        }
        this.status="SUCCESS"
        // console.log("Upload completed (" + this.attachments.length + " files).")
      },
    },
    computed:{
      submitting: function(){
        if (this.status=='LOADING')
          return true
        else
          return false
      }
    },
  }
</script>