import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Reference from '../views/Reference.vue'
import Impressum from '../views/Impressum.vue'
import CookieInfo from "../views/CookieInfo"
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reference',
    name: 'Reference',
    component: Reference,
    props: true
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
  },
  {
    path: "/cookieinfo",
    name: "CookieInfo",
    component: CookieInfo
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (){
    return { x: 0, y: 0 }
  },
  pushWithAnchor: function (routeName, toHash) {
    const fromHash = router.history.current.hash
    fromHash !== toHash || !fromHash
    ? router.push({ name: routeName, hash: toHash })
    : router.push({ name: routeName, hash: fromHash }, undefined, () => { window.location.href = toHash })
  }
})



export default router
