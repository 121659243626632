import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/compat/app'
require('firebase/compat/firestore')
require('firebase/compat/app')
import VueGtag from 'vue-gtag'
import cookieconsent from 'vue-cookieconsent-component'


Vue.config.productionTip = false
Vue.component('cookie-consent', cookieconsent)
Vue.use(VueGtag, {
  config: { id: "UA-207148306-1" }
}, router);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

const firebaseConfig = {
  apiKey: "AIzaSyDHK2BNOUCh9Z5rWDLuW-VkjRCgurcYDKQ",
  authDomain: "meinhaus-3d.firebaseapp.com",
  projectId: "meinhaus-3d",
  storageBucket: "meinhaus-3d.appspot.com",
  messagingSenderId: "998379474319",
  appId: "1:998379474319:web:08c2455bb70241d4944ffa",
  measurementId: "UA-207148306-1"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.prototype.$firebaseDatabase = firebase.default.firestore()
export const FirebaseStorage = firebase.storage();
//  const analytics = getAnalytics(app);