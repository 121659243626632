<template>
  <v-main class="teal lighten-5 py-6 fill-height">
      <v-container v-if="reference">
        <v-row class="mb-6">
          <v-col offset-xl="1">
              <v-btn large text @click="$router.push('/')"><v-icon class="mr-2">mdi-arrow-left-circle</v-icon>zurück</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" xl="3" offset-xl="1">
            <v-card flat>
              <v-card-title>
                {{ reference.title }}
              </v-card-title>
              <v-card-subtitle>
                {{ reference.subtitle }}
              </v-card-subtitle>
              <v-card-text>
                <span style="white-space: pre-line">
                  {{ reference.description }}
                </span>
              </v-card-text>
              <v-card-text>
                <p><strong>Maßstab:</strong> {{ reference.scale }}</p>
                <p><strong>Maße (BxTxH in cm):</strong> {{reference.width}} x {{ reference.depth }} x {{ reference.height }}</p>
                <p><strong>Preis:</strong> {{ reference.price }} <br/><span class="caption">(Erstellung des 3D-Modells sowie Druck in 2 Farben)</span></p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="8" xl="6">
            <v-card>
              <v-carousel
                height="100%"
              >
                <v-carousel-item v-for="(refImg, k) in reference.img" :key="k">
                  <v-img
                    :src= refImg.src
                    :lazy-src= refImg.lazy
                    :aspect-ratio="4/3"
                  >
                  </v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-6" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col offset-xl="1">
              <v-btn large text @click="$router.push('/')"><v-icon class="mr-2">mdi-arrow-left-circle</v-icon>zurück</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</template>

<script>
  export default {
    props: 
    {
      reference: {
        type: Object,
      }
    },
    data: () => ({
          title: 'Bungalow auf 1 Etage',
          text: 'Erstellung des Modells & 3D-Druck',
          price: 'EUR 119,-',
          img:[
            { src: require('@/assets/refs/Modell_B-1.jpg'), lazy: require('@/assets/refs/80/Modell_B-1.jpg'), },
            { src: require('@/assets/refs/Modell_B-2.jpg'), lazy: require('@/assets/refs/80/Modell_B-2.jpg'), },
            { src: require('@/assets/refs/Modell_B-3.jpg'), lazy: require('@/assets/refs/80/Modell_B-3.jpg'), },
            { src: require('@/assets/refs/Modell_B-4.jpg'), lazy: require('@/assets/refs/80/Modell_B-4.jpg'), },
            { src: require('@/assets/refs/Modell_B-5.jpg'), lazy: require('@/assets/refs/80/Modell_B-5.jpg'), },
          ],
    }),
    methods: {
      logRef(){
        console.log(this.reference)
      }
    },
    mounted(){
      if(!this.reference){
        this.$router.push("/");
      }
    },
    metaInfo() {
      return {
        title: `${this.reference.title}`,
        meta: [
          {
            name: 'description',
            content: this.reference.description.slice(0,100)
          }
        ]
      }
    }
  }
</script>

<style>

</style>