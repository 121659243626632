<template>
  <div>
    <cookie-consent
      style="position:relative"
      class="teal white--text"
      v-if="cookieHint"
    >
      <template slot="message">
        <span style="font-size:0.8em"
          >Diese Seite verwendet Cookies für Funktions-, Komfort- und
          Statistikzwecke. Wenn Sie auf der Seite weitersurfen, akzeptieren Sie
          die Cookie-Verwendung.
        </span>
        <a href="/cookieInfo" @click="moreCookieInfo()" class="mx-1 white--text"
          ><span style="font-size:0.9em; text-decoration:underline;"
            >Weitere Infos</span
          ></a
        >
      </template>
      <template slot="button">
        <v-btn
          class="mx-3 greend--text"
          style="border:1px solid greend !important"
          elevation="0"
          color="white"
          @click="cookieAccept()"
          >OK</v-btn
        >
      </template>
    </cookie-consent>
  </div>
</template>

<script>
export default {
  name: "Cookiebar",

  data() {
    return {
      cookieHint: true
    };
  },
  methods: {
    cookieAccept() {
      this.$cookies.set("cookieAccepted", true);
      this.cookieHint = false;
      this.$ga.enable();
      this.$ga.event("Cookies", "Cookies akzeptiert", "Akzeptieren_Cookiebar");
    },
    moreCookieInfo() {
      this.$router.push("cookieInfo");
    }
  },
  mounted() {
    if (this.$cookies.get("cookieAccepted")) {
      this.cookieHint = this.$cookies.get("cookieAccepted");
      this.$ga.enable();
    } else {
      this.$ga.disable();
    }
  }
};
</script>

<style lang="scss">
@import "node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
</style>
