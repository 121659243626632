<template>

    <section id="how">
        <v-layout
          column
          wrap
          align-center
          class="py-6"
        >
          <h4 class="mt-4 subheading font-weight-thin">
            So funktioniert <br v-if="$vuetify.breakpoint.xs"/>MeinHaus-3D
          </h4>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center justify-center>
                <v-flex xs12 md3>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large class="teal--text">mdi-email-check-outline</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-center">Unverbindliche Anfrage</div>
                    </v-card-title>
                    <v-card-text class="text-center">
                      Senden Sie uns eine Anfrage mit Ihren Skizzen, dem Grundriss, den Maßen und/oder Fotos Ihres Hauses.
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md3>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large class="teal--text">mdi-transit-connection-variant</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">Individuelles Angebot</div>
                    </v-card-title>
                    <v-card-text class="text-center">
                      Sie erhalten von uns ein unverbindliches Angebot für die Erstellung Ihres persönlichen 3D-Modells.
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md3>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large class="teal--text">mdi-home-outline</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-center">Erstellung des Modells</div>
                    </v-card-title>
                    <v-card-text class="text-center">
                      Nach Ihrer Zustimmung erstellen wir Ihr 3D-Modell und senden es zeitnah auf dem Postweg an Ihre Anschrift.
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
    </section>
</template>
      
      <script>
      export default {
      
      }
      </script>
      
      <style>
      
      </style>