<template>
  <div class="home">
    <Stage />
    <Processflow />
    <References />
    <Requestform />
  </div>
</template>

<script>
import Stage from '@/components/home/Stage.vue'
import Processflow from '@/components/home/Processflow.vue'
import References from '@/components/home/References.vue'
import Requestform from '@/components/home/Requestform.vue'

export default {
  name: 'Home',
  metaInfo: {
      title: 'MeinHaus-3D',
      // override the parent template and just use the above title only
      titleTemplate: null
    },
  components: {
    Stage,
    Processflow,
    References,
    Requestform
  },
}
</script>
