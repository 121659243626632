<template>
  <v-main>
    <section>
      <v-card color="transparent" max-width="600" class="mx-auto mt-4 mb-12" flat>
        <v-card-text class="">
          <p>Diese Webseite verwendet Cookies.</p>
          <p>
            Wir verwenden Cookies auf unserer Website, einige sind zwingend
            erforderlich zum Funktionieren der Website, andere helfen uns, die
            Website und Ihr Erlebnis zu verbessern.
          </p>
          <p>
            Cookies sind kleine Textdateien, die von Webseiten verwendet werden,
            um die Benutzererfahrung effizienter zu gestalten. Laut Gesetz
            können wir Cookies auf Ihrem Gerät speichern, wenn diese für den
            Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen
            Cookie-Typen benötigen wir Ihre Erlaubnis.
          </p>
          <p>
            Mit der Nutzung dieser Webseite geben Sie Ihr Einverständnis zur
            Nutzung von Cookies. Alle Cookies werden nach 90 Tagen gelöscht.
          </p>
          <p>
            Ihre Einwilligung trifft auf die folgenden Domains zu:
            www.MeinHaus-3D.de
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined class="mx-auto greend--text" @click="routerBack()"
            >Zurück</v-btn
          >
        </v-card-actions>
        <!-- <v-card-actions v-if="disableHint">
          <p class="mx-auto text-center grey--text">
            Tracking deaktiviert. <br />
            <a v-if="disableHint" @click="enableTracking()"
              >Cookies und Tracking wieder aktivieren</a
            >
          </p>
        </v-card-actions>
        <v-card-actions>
          <a
            class="mx-auto grey--text"
            v-if="!disableHint"
            @click="disableTracking()"
            >Cookies und Tracking deaktivieren</a
          >
        </v-card-actions> -->
      </v-card>
    </section>
  </v-main>
</template>

<script>
export default {
  name: "CookieInfo",
  metaInfo: {
      title: 'Cookie-Informationen',
    },

  data() {
    return {
      disableHint: false
    };
  },
  components: {},
  mounted() {},
  methods: {
    routerBack() {
      history.back();
    },
    disableTracking() {
      this.$ga.event(
        "Cookies",
        "Tracking deaktiviert",
        "Deaktivieren_CookieInfo"
      );
      this.$ga.disable();
      console.log("Tracking deaktiviert.");
      this.disableHint = true;
    },
    enableTracking() {
      this.$ga.enable();
      this.$ga.event("Cookies", "Tracking aktiviert", "Aktivieren_CookieInfo");
      console.log("Tracking aktiviert.");
      this.disableHint = false;
    }
  }
};
</script>
